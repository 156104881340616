


























































import { Component, Ref, Vue, Watch } from 'vue-property-decorator' ;
import { VueCsvImport } from 'vue-csv-import';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { ConfiguracaoDeCupom, EstoqueCsvForm } from '@/models';
import SeletorDeGruposEconomicos from "@/components/loja/SeletorDeGruposEconomicos.vue"
import { obrigatorio } from '@/shareds/regras-de-form'
import { FindConfiguracaoDeCupomUseCase } from '@/usecases';
import { GrupoEconomico } from '@/models/GrupoEconomico';

@Component({
	components: {
		SeletorDeGruposEconomicos,
	},
})
export default class DialogoDeCsvCupom extends Vue {
	@Ref() csvComponent!: VueCsvImport
	@Ref() form!: HTMLFormElement

	obrigatorio = obrigatorio
	findConfiguracaoDeCupomUseCase = new FindConfiguracaoDeCupomUseCase()

	mostra = false

	grupoEconomico: GrupoEconomico | null = null
  
	cupons: ConfiguracaoDeCupom[] | [] = []
	cupomSelecionado: string = ''

	csv: EstoqueCsvForm[] | null = null
		
	arquivo = 'File'

	inputFile: HTMLInputElement | null = null

	carregandoComparacao = false

	mostrar(){
		this.mostra = true
	}

	cancelar() {
		this.csv = null;
		if (this.csvComponent) {
			this.csvComponent.sample = null;
			this.csvComponent.fileSelected = false;
			this.csvComponent.map = [];
		}
		this.cupons = [];
		this.cupomSelecionado = '';
		this.grupoEconomico = null;
		this.mostra = false;
		if (this.inputFile != null) {
			this.inputFile.value = '';
		}
	}


	async exportarCsv() {
		this.form.validate()
		if (!this.grupoEconomico) {
			AlertModule.setError('Selecione um Grupo Economico!')
			return
		}

		if (!this.cupomSelecionado) {
			AlertModule.setError('Selecione um cupom!')
			return
		}

		try {

			const cupomFiltrado = this.cupons.find(cupom => this.cupomSelecionado === cupom.id);
			if(!cupomFiltrado)return

			const filtrosFormatados = {
				grupoId: this.grupoEconomico.id,
				configuracaoDeCupomId: cupomFiltrado.id,
				nomeDoCupom: cupomFiltrado.nome
			}
			
			const response = await this.findConfiguracaoDeCupomUseCase.findIdentificadoresDosCupons(
				filtrosFormatados
			)

			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a')

			const dataAtual = new Date()

			const dataFormatada = dataAtual.toLocaleDateString().replaceAll('-', '_')
			const horaFormatada = dataAtual.toLocaleTimeString().replaceAll(':', '_')

			link.href = url
			link.setAttribute('download', `Identificadores_de_cupom_${dataFormatada + '_' + horaFormatada}.csv`)
			document.body.appendChild(link)
			link.click()
		} catch (e) {
			AlertModule.setError(e)
		} finally {
		}
	}

	@Watch("grupoEconomico")
	async onChangeGrupoEconomico(){console.log(this.grupoEconomico);
		if(this.grupoEconomico){
			this.cupons = await this.findConfiguracaoDeCupomUseCase.findCuponsValidos(this.grupoEconomico.id)	
		}
	}
}
